<template>
  <div>
    <CageList></CageList>
    <ManageSica></ManageSica>
  </div>
</template>

<script>
// @ is an alias to /src
import CageList from '@/components/cages/list.vue'
import ManageSica from '@/components/cages/ManageSica.vue'

export default {
  name: 'HomeView',
  components: {
    CageList,
    ManageSica
  },
  data () {
    return {
  
    }
  },
  created () {

  },
}
</script>
