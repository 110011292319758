<template>
  <div>
    <nav class="nav-inicio ">
      <div class="container-fluid">
        <h2 class="title">SICA</h2>
      </div>
    </nav>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: "SicaHeader",
  components: {
  },
  data() {
    return {
    };
  },
  computed: { 

  },
  methods:{
    logout: function() {
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
.title{
  color: #4ca7db;
  font-size: 2.3em;
  letter-spacing: 0.1em;
}
.nav-inicio {
  margin-top: 15px;
  text-align: center;
  align-content: center;
}

</style>
