import * as localData from "@/services/localData.js";
import Vue from "vue";
import router from '@/router';
import {
  toaster_success,
  toaster_error,
  toaster_info,
} from "@/services/helper.js";

const state = () => ({
  status: "",
  auth_token: localData.getlocal("auth_token") || "",
  refresh_token: localData.getlocal("refresh_token") || "",
  username: localData.getlocal("username") || "",
  user_id: localData.getlocal("user_id") || "",
  is_admin: localData.getlocal("is_admin") || "",
  first_name: localData.getlocal("first_name") || "",
  last_name: localData.getlocal("last_name") || "",
  email: localData.getlocal("email") || "",
  roles: localData.getArraylocal("roles") || [],
  user_type: localData.getlocal("user_type") || "",
});

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, data) {
    state.status = "success";
    state.auth_token = data.token;
    state.refresh_token = data.refreshToken;
    state.username = data.userName;
    //state.user_id = data.user_id;
    //state.is_admin = data.is_admin;
    state.first_name = data.tokenParsed.given_name;
    state.last_name = data.tokenParsed.family_name;
    state.email = data.tokenParsed.email;
    state.roles = data.realmAccess.roles;
    //state.user_type = data.user_type;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.auth_token = "";
    state.refresh_token = "";
    state.username = "";
    state.user_id = "";
    state.is_admin = "";
    state.first_name = "";
    state.last_name = "";
    state.email = "";
    state.user_type = "";
    state.roles = "";
  },
  auth_validate(state, auth_token) {
    state.status = "success";
    state.auth_token = auth_token;
  },
};

const actions = {
  setUserParams({ commit },user) {
    return new Promise((resolve, reject) => {
      
      localData.setlocalToken("auth_token", user.token);
      localData.setlocal("refresh_token", user.refreshToken);

      if (localStorage.username != user.userName){
        localData.setlocal("username", user.userName);
        //localData.setlocal("user_id", user.user_id);
        //localData.setlocal("is_admin", user.is_admin);
        localData.setlocal("first_name", user.tokenParsed.given_name);
        localData.setlocal("last_name", user.tokenParsed.family_name);
        localData.setlocal("email", user.tokenParsed.email);
        //localData.setlocal("user_type", user.user_type);
        localData.setlocal("roles", user.realmAccess.roles);

        commit("auth_success", user);
        toaster_success("Welcome back to QUIETSEAS, " + user.userName);
      }

    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      router.push('/')
      Vue.prototype.$keycloak.logoutFn()
      commit("logout")
      localData.deleteAll()
      resolve("OK");
    });
  },
};
const getters = {
  isLoggedIn: (state) => !!state.auth_token,
  authStatus: (state) => state.status,
  token: (state) => state.auth_token,
  username: (state) => state.username,
  user_id: (state) => state.user_id,
  is_admin: (state) => state.is_admin,
  first_name: (state) => state.first_name,
  last_name: (state) => state.last_name,
  email: (state) => state.email,
  user_type: (state) => state.user_type,
  roles: (state) => state.roles,
  is_technician: (state) => {
    return state.user_type == "technician" ? true : false;
  },
  is_manager: (state) => {
    return state.user_type == "manager" ? true : false;
  },
  is_coordinator: (state) => {
    return state.user_type == "coordinator" ? true : false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
