
import { getData } from '@/services/api'
import store from '@/store/index'
import Vue from "vue";

const toaster_success = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "success",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

const toaster_error = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "error",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

const toaster_info = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "info",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

export { toaster_success, toaster_error, toaster_info };


export function capitalizeFirstLetter(string) {
    if(string)
        return string.charAt(0).toUpperCase() + string.slice(1);
    else
        return '-------'
}

export function timestampToDate (timestamp) {
    var day = new Date(timestamp);
    var year = day.getFullYear()
    var month = day.getMonth() + 1;
    if(month <= 9) month = '0' + month 
    var date = day.getDate();
    if(date <= 9) date = '0' + date 
    var hour = day.getHours();
    if(hour <= 9) hour = '0' + hour
    var min = day.getMinutes();
    if(min <= 9) min = '0' + min
    var time = date + '-' + month + '-' + year + ' ' + hour + ':' + min;

    return time
}

export function formatDate (inputDateString){
    var inputDate = new Date(inputDateString)

    var mm = inputDate.getMonth() + 1 // getMonth() is zero-based
    var dd = inputDate.getDate()
    var hh = inputDate.getHours()
    var mi = inputDate.getMinutes()
    var ss = inputDate.getSeconds()

    return [
            (dd>9 ? '' : '0') + dd,
            (mm>9 ? '' : '0') + mm,       
            inputDate.getFullYear()     
        ].join('-') 
        + '  ' + 
        [
            (hh>9 ? '' : '0') + hh,
            (mi>9 ? '' : '0') + mi,       
            (ss>9 ? '' : '0') + ss,                 
        ].join(':')
}