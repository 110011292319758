<template>
    <div>
        <div class="container-fluid mt-4">
            <div class="row text-center">
                <div v-if="this.cages_list.length > 0" class="col-12 dropdown ">
                    <button class="btn cage-list-select list-text list-text-select pl-0 pr-0 dropdown-toggle w-100"   v-bind:class="{ 'cage-list-select-active': aciveList }" type="button" v-on:click="showList" >
                        {{this.cages_list[selected_cage].sica_id}}
                    </button>
                    <ul v-if="aciveList" class=" dropdown-item-text cage-list text-center">
                        <li v-for="(value, key) in cages_list"><a class="dropdown-item list-text" href="#" v-on:click="changeCage(key)">{{value.sica_id}} </a></li>
                    </ul> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: "cageList",
    components: {
    },
    data() {
      return {
        aciveList: false,
      };
    },
    computed: { 
        ...mapGetters(['cages_list']),
        selected_cage: {
            set (cage) { this.$store.dispatch('change_selected_cage', cage); },
            get () { return this.$store.state.selected_cage}
        },
  
    },
    created(){
        this.$store.dispatch('getSicaCages',true)
    },
    methods:{
        changeCage: function (cage_index) {
            this.selected_cage = cage_index
            this.aciveList = false
        },
        showList: function () {
            this.aciveList = !this.aciveList
        }
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang= "scss" scoped>

  .list-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 2em;
    color: #fff; 
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .list-text-select{
    color: #556e7c;
  }

  .cage-list-select {
    box-sizing: border-box;
    background: #B3E3FF;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

  }
  .cage-list-select-active{
    border-bottom: none;
    border-radius: 12px 12px 0px 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  }
  .cage-list {
    box-sizing: border-box;
    background: #B3E3FF;
    border: 1px solid #000000;
    border-top: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 12px 12px;
    padding-right: 33px;

  }
  .dropdown-item-text {
    z-index: 1000;
    position: absolute;
    margin: 0px;
    width: calc(100% - 24px);
  }
  .dropdown-item:hover {
    color: #556e7c;
  }

  </style>
  