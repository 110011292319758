import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'
import axios from 'axios'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './registerServiceWorker'
import router from './router'
import store from "./store";
import VueToggles from 'vue-toggles'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

Vue.component('VueToggles', VueToggles);

const setUserParams = (userParams) => {
  store.dispatch('user/setUserParams', userParams)
}

Vue.use(VueKeyCloak, {
  config: {
    realm: process.env.VUE_APP_KEYCLOAK_REAL,
    url: process.env.VUE_APP_KEYCLOAK_HTTP_S + '://' + process.env.VUE_APP_KEYCLOAK_HOST + '/auth/',
    clientAuthenticatorType: "client-secret",
    clientId:process.env.VUE_APP_KEYCLOAK_CIIENT_ID,
  },
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false
  },
  onReady: kc => {
    setUserParams(Vue.prototype.$keycloak)
    new Vue({
      beforeCreate () {
        Vue.prototype.$http = axios
        //axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
        //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*' 
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
        axios.defaults.xsrfCookieName = 'csrftoken' 

      },
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
})

