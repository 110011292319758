<template>
  <footer>
         Developed by CTN www.ctnaval.com
    </footer>
</template>

<script>
export default {
  name: 'SicaFooter',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

  footer{
    text-align: right;
    /* padding-right: 200px; */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $footer-default;
    color: $footer-text-default;
    padding: 0.2rem 1.2rem 0.2rem 0.5rem;
    margin-top: 20px;
  }
</style>
