import Vue from 'vue'
import Vuex from 'vuex'
import { getData, setData } from '@/services/api'
import user from "./modules/user.js";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user
  },
  state: {
    ws_sica_control: '',
    cages_list:[],
    selected_cage:0
  },
  mutations: {
    set_ws_sica_control(state, ws){
      state.ws_sica_control = ws
    },
    set_sica_cages(state, list){
      state.cages_list = list
    },
    set_selected_cage(state, cage){
      state.selected_cage = cage
    }
  },
  actions: {
    change_selected_cage({ commit }, cage){
      commit('set_selected_cage', cage)
    },
    getSicaCages({ commit }) {
      let url = 'management/sicaStation/'
      getData(url).then(res=> {
        commit("set_sica_cages", res.data.results)
      }) 
    },
  },
  getters: {
    cages_list: state => state.cages_list,
    selected_cage: state => state.selected_cage,
    ws_sica_control: state => state.ws_sica_control
  }
})
