<template>
    <div class="container-fluid text-center mt-5">
        <div class="row align-content-center" >
            <div class="col-auto" style="margin-left:auto;margin-right:auto;">
              <VueToggles
                  height="70"
                  width="125"
                  checkedText="ON"
                  uncheckedText="OFF"
                  checkedBg="green"
                  uncheckedBg="red"
                  fontSize="17"
                  :value="sica_active"
                  @click="updateSicaStatus(!sica_active)"
                />
            </div>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { mapGetters } from 'vuex'
  export default {
    name: 'ManageSica',
    components: {
    },
    data () {
        return {
            connection: null,
        }
    },
    computed:{
        ...mapGetters(['selected_cage','cages_list']),
        sica_active() {
          if(this.cages_list.length > 0 && this.cages_list[this.selected_cage].status == 'active')
            return true
          else
            return false
        }
    },
    mounted () {
        var ws_url = []
        console.log(process.env)
        if (process.env.VUE_APP_API_TYPE == 'local')
            ws_url = process.env.VUE_APP_WS_S  + '://' + process.env.VUE_APP_API_HOST + ':' + process.env.VUE_APP_API_PORT + '/ws/sicaControlConsumer/'
        else
            ws_url = process.env.VUE_APP_WS_S  + '://' + process.env.VUE_APP_API_HOST_PRO + '/ws/sicaControlConsumer/'
        this.connection = new WebSocket(ws_url)

        this.connection.addEventListener('open', () => {
            console.log('Conectado')
        })
        var thisglobal = this
        this.connection.onmessage = function (event) {
            const data = JSON.parse(event.data)
            thisglobal.$store.dispatch('getSicaCages',false)
            console.log('Message from server: ', data);
        }
  },
  methods: {
    updateSicaStatus: function(status) {
      var message = {}
      if(status){
        message = {
            "origin": 'app',
            "sica_id": this.cages_list[this.selected_cage].sica_id,
            "action_type":"start"
        }
      }else{
        message = {
            "origin": 'app',
            "sica_id": this.cages_list[this.selected_cage].sica_id,
            "action_type":"stop"
        }
      }
     
      this.connection.send(JSON.stringify(message));
      //this.$store.dispatch('getSicaCages',false)
    }
  }

  }
  </script>
  